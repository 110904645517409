import React from 'react';

import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';
import { CTA, Brand, Navbar } from './components';

import './App.css';
import Brand2 from './components/brand/Brand2';

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Brand />
    {/* <WhatGPT3 /> */}
    <Features />
    {/* <Possibility /> */}
    {/* <CTA /> */}
    {/* <Blog /> */}
    {/* <Footer /> */}
    <CTA />
  </div>
);

export default App;
