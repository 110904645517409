import React from 'react';
import { java, cs, cpp, py, ts, js, go } from './imports';
import './brand.css';
import './features.css';

const Brand = () => (
  <div>
    <div className="gpt3__brand section__padding">
      <div>
        <img src={java} />
      </div>
      <div>
        <img src={cs} />
      </div>
      <div>
        <img src={cpp} />
      </div>
      <div>
        <img src={py} />
      </div>
      <div>
        <img src={ts} />
      </div>
      <div>
        <img src={js} />
      </div>
      <div>
        <img src={go} />
      </div>
    </div>
  </div>
);

export default Brand;
