import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import cat from '../../assets/cat.png';
import './header.css';
import { TypeAnimation } from 'react-type-animation';


function Header() {
  // const names = ["Hello there!", "你好!", "Apa Kabar!"];
  // const [newName, setnewName] = useState("");

  // const shuffle = useCallback(() => {
  //     const index = Math.floor(Math.random() * names.length);
  //     setnewName(names[index]);
  // }, []);

  // useEffect(() => {
  //     const intervalID = setInterval(shuffle, 1000);
  //     return () => clearInterval(intervalID);
  // }, [shuffle]);


    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const texts = ["Software Engineer", "Frontend Web Developer", "Backend Web Developer"];
    const [text, setText] = useState("");
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000; // in milliseconds

    const write = () => {
        let i = loopNum % texts.length;
        let fullText = texts[i];
        let updatedText = isDeleting 
            ? fullText.substring(0, text.length - 1)
            : fullText.substring(0, text.length + 1);
        setText(updatedText);

        // rate for deleting is higher than rate for typing
        if (isDeleting) {
            setDelta(prev => prev / 2);
        }

        // when writer finishes typing out the full text
        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === "") {
            // when writer finishes deleting the full text
            setIsDeleting(false);
            setLoopNum(prev => prev + 1); // go to next word
            const delta = 100; // increase delta to reduce typing speed
            setDelta(delta);
        }
    }

    useEffect(() => {
        let writer = setInterval(() => {
            write();
        }, delta);

        return () => {
            clearInterval(writer);
        }
    }, [text]);

  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        {/* <h1 className="gradient__text">Let&apos;s Build Something amazing with GPT-3 OpenAI</h1> */}
        {/* <h1 className="gradient__text">{newName}</h1> */}
        <h1 className="gradient__text">Hello there, I'm Steven.</h1>
        <h1>
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              'I am a Software Engineer.',
              1000, // wait 1s before replacing "Mice" with "Hamsters"
              'I am a Computer Scientist.',
              1000,
              'I am a Chess Enthusiast.',
              1000
            ]}
            wrapper="span"
            speed={40}
            repeat={Infinity}
            style={{ fontSize: '2rem' , letterSpacing: '0.1px', fontFamily: 'inherit', color: 'white' }}
            cursor={false}
          />
        </h1>
        {/* <h1 className="gradient__text">My name is Steven.</h1> */}
        <p>I am an undergraduate student at the National University of Singapore (NUS) studying Computer Science.</p>

        {/* <div className="gpt3__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div> */}

        {/* <div className="gpt3__header-content__people">
          <img src={people} />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div> */}
      </div>

      <div className="gpt3__header-image">
        <img src={cat} className="move"/>
      </div>
    </div>
  );
}

export default Header;
