import React from 'react';
import './cta.css';
import { github, linkedin } from '../brand/imports';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      {/* <p>Request Early Access to Get Started</p> */}
      <h3 className='black'>Connect with me!</h3>
    </div>
    {/* <div className="gpt3__cta-btn"> */}
    <div>
      <a href="https://www.linkedin.com/in/stevenlimhw/" target="_blank"><img width="60px" src={linkedin}/></a>
      <a href="https://github.com/stevenlimhw" target="_blank"><img width="60px" src={github}/></a>
    </div>
  </div>
);

export default CTA;
